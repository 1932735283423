<template>
  <v-dialog
    v-model="show"
    :fullscreen="isPhone"
    max-width="900"
    persistent
    overlay-color="#1f2c4c"
    overlay-opacity="70"
    @keydown.esc="cancel"
  >
    <v-card flat class="noscroll-x">
      <v-sheet color="lightHeader" class="silver--text">
        <v-card-title class="title">
          Add Product
          <v-spacer />
          <v-icon color="silver" large @click="cancel" title="Close">
            mdi-close
          </v-icon>
        </v-card-title>
      </v-sheet>
      <v-card-actions class="mb-2">
        <v-divider />
        <span class="mx-2 accent--text">Product Information</span>
        <v-divider />
      </v-card-actions>
      <v-card-actions>
        <v-spacer />
        <v-checkbox
          :color="product.status == 'Published' ? 'accent' : 'silver'"
          class="enlarged-checkbox "
          true-value="Published"
          false-value="Draft"
          v-model="product.status"
          label="Publish Upon Creation?"
        />
      </v-card-actions>
      <v-row dense class="px-2">
        <v-col cols="12" md="6">
          <v-text-field
            :error-messages="nameErrors"
            v-model="product.name"
            ref="name"
            :counter="50"
            label="Product Name"
            prepend-icon="mdi-account"
            @blur="$v.product.name.$touch()"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            v-model="product.type"
            chips
            prepend-icon="mdi-view-list"
            :items="productTypes"
            label="Product Type"
            outlined
            item-text="name"
            item-value="name"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            :error-messages="productDescriptionErrors"
            v-model="product.description"
            ref="description"
            :counter="400"
            :rows="3"
            label="Product Description"
            prepend-icon="mdi-text"
            @blur="$v.product.description.$touch()"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :error-messages="statementErrors"
            hint="Display on the user's bank statement"
            v-model="product.statement_descriptor"
            ref="statementDescriptor"
            :counter="20"
            label="Statement Descriptor"
            prepend-icon="mdi-package-variant"
            @blur="$v.product.statement_descriptor.$touch()"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :error-messages="packageErrors"
            v-model="product.package"
            ref="package"
            :counter="20"
            label="Package"
            prepend-icon="mdi-package-variant"
            @blur="$v.product.package.$touch()"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :error-messages="priorityErrors"
            hint="Determines the order in which products are showsn. Lowest first."
            v-model="product.priority"
            ref="priority"
            :counter="3"
            label="Priority"
            type="number"
            prepend-icon="mdi-priority-high"
            @blur="$v.product.priority.$touch()"
          />
        </v-col>
      </v-row>

      <v-spacer />
      <v-card-actions class="mt-4 accent--text">
        <v-divider />
        <span class="mx-2">Price Information</span>
        <v-divider />
      </v-card-actions>
      <v-row dense class="px-2">
        <v-col cols="12" md="6">
          <v-text-field
            :error-messages="skuErrors"
            v-model="variation.sku"
            hint="Unique Product Variation Code"
            ref="sku"
            :counter="25"
            label="SKU"
            prepend-icon="mdi-account"
            @blur="$v.variation.sku.$touch()"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            :error-messages="descriptionErrors"
            v-model="variation.description"
            ref="description"
            :counter="400"
            :rows="3"
            label="Price Description"
            prepend-icon="mdi-text"
            @blur="$v.variation.description.$touch()"
          />
        </v-col>
      </v-row>
      <v-row dense class="px-2">
        <v-col :cols="variation.use_stock == 'Yes' ? 4 : 12">
          <v-checkbox
            :color="variation.use_stock ? 'silver' : 'accent'"
            class="enlarged-checkbox "
            true-value="Yes"
            false-value="No"
            v-model="variation.use_stock"
            label="Use Stock"
          />
        </v-col>
        <template v-if="variation.use_stock == 'Yes'">
          <v-col cols="4">
            <v-text-field
              v-model="variation.stock"
              ref="stock"
              :counter="5"
              type="number"
              label="Stock"
              prepend-icon="mdi-link-box-outline"
              hint="Initial Stock"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="variation.limit_qty"
              ref="limitqty"
              :counter="5"
              label="Limit Quantity"
              type="number"
              prepend-icon="mdi-link-box-outline"
              hint="Buy max #"
            />
          </v-col>
        </template>
      </v-row>
      <v-row dense class="px-2">
        <v-col cols="12" md="6">
          <v-text-field
            :error-messages="priceErrors"
            v-model="variation.price"
            ref="price"
            :counter="10"
            label="Price"
            type="number"
            prepend-icon="mdi-cash"
            @blur="$v.variation.price.$touch()"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            v-model="variation.currency"
            ref="currency"
            chips
            prepend-icon="mdi-currency-usd"
            :items="currencies"
            label="Currency"
            outlined
            item-text="name"
            item-value="value"
          />
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="variation.recurrence"
            ref="recurrence"
            chips
            prepend-icon="mdi-autorenew"
            :items="productRecurrence"
            label="Product Recurrence"
            outlined
            item-text="name"
            item-value="value"
            @change="changeRecurrence"
          />
        </v-col>
        <template v-if="this.variation.recurrence != 'none'">
          <v-col cols="6">
            <v-text-field
              :error-messages="intervalErrors"
              :key="switchNum"
              v-model="variation.interval"
              ref="interval"
              :counter="3"
              label="Interval"
              type="number"
              prepend-icon="mdi-clock-start"
              @blur="$v.variation.interval.$touch()"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              :error-messages="graceErrors"
              v-model="variation.grace"
              ref="grace"
              :counter="3"
              label="Grace Period (days)"
              type="number"
              prepend-icon="mdi-send-clock"
              @blur="$v.variation.grace.$touch()"
            />
          </v-col>
        </template>
      </v-row>
      <v-card-subtitle :class="textSizeXSmall + ' pt-2'">
        Click the <span class="accent--text">Add Product</span> button to create
        the product.
      </v-card-subtitle>
      <v-spacer />
      <v-card-actions class="px-0">
        <BaseActionButton
          icon="mdi-close"
          label="Cancel"
          color="info"
          plain
          @clickedThis="cancel"
        />
        <v-spacer />
        <BaseActionButton
          :disabled="this.$v.$anyError"
          plain
          label="Add Product"
          icon="mdi-plus-circle-outline"
          :large="!isPhone"
          @clickedThis="addProduct"
        />
      </v-card-actions>
    </v-card>
    <BaseSnackBar :show="snackbar" :snack="snackText" />
  </v-dialog>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import {
  required,
  maxLength,
  minLength,
  minValue,
  maxValue
} from 'vuelidate/lib/validators'

export default {
  mixins: [util],
  validations: {
    variation: {
      sku: { required, minLength: minLength(3), maxLength: maxLength(25) },
      description: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(400)
      },
      price: { required, minValue: minValue(0), maxValue: maxValue(99999) },
      grace: { minValue: minValue(0), maxValue: maxValue(99) },
      interval: { minValue: minValue(0), maxValue: maxValue(999) }
    },
    product: {
      name: { required, minLength: minLength(3), maxLength: maxLength(50) },
      description: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(400)
      },
      statement_descriptor: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(20)
      },
      package: {
        maxLength: maxLength(20)
      },

      priority: { minValue: minValue(0) }
    }
  },
  props: {
    show: {
      type: Boolean
    },
    businessid: {
      type: String
    },
    productTypes: {
      type: Array
    }
  },
  data() {
    return {
      initVariation: {
        interval: 0,
        recurrence: 'none',
        use_stock: 'No',
        stock: 0,
        limit_qty: 0,
        grace: 0,
        currency: 'USD',
        price: 0,
        value: '',
        value_details: ''
      },
      variation: {},
      initProduct: {
        name: '',
        description: '',
        statement_descriptor: '',
        package: '',
        priority: 99,
        status: 'Published'
      },
      product: {},
      productRecurrence: [],
      currencies: [],
      snackbar: false,
      snackText: '',
      timeout: 1000,
      switchNum: 0
    }
  },
  computed: {
    nameErrors() {
      const errors = []
      if (!this.$v.product.name.$dirty) return errors
      !this.$v.product.name.required &&
        errors.push('Please enter a product name.')
      !(this.$v.product.name.minLength && this.$v.product.name.maxLength) &&
        errors.push('Name length not in range. Minimum 5 maximum 400.')
      return errors
    },
    statementErrors() {
      const errors = []
      if (!this.$v.product.statement_descriptor.$dirty) return errors
      !this.$v.product.statement_descriptor.required &&
        errors.push('Please enter a statement descriptor.')
      !(
        this.$v.product.statement_descriptor.minLength &&
        this.$v.product.statement_descriptor.maxLength
      ) &&
        errors.push(
          'Statement descriptor length not in range. Minimum  maximum 20.'
        )
      return errors
    },
    packageErrors() {
      const errors = []
      if (!this.$v.product.package.$dirty) return errors
      !this.$v.product.package.maxLength &&
        errors.push('Package length not in range. Maximum 20.')
      return errors
    },
    productDescriptionErrors() {
      const errors = []
      if (!this.$v.product.description.$dirty) return errors
      !this.$v.product.description.required &&
        errors.push('Please enter a description.')
      !(
        this.$v.product.description.minLength &&
        this.$v.product.description.maxLength
      ) &&
        errors.push('Description length not in range. Minimum 5 maximum 400.')
      return errors
    },
    priorityErrors() {
      const errors = []
      if (!this.$v.product.priority.$dirty) return errors
      !this.$v.product.priority.minValue &&
        errors.push('Priority not in range. Minimum 0.')
      return errors
    },
    skuErrors() {
      const errors = []
      if (!this.$v.variation.sku.$dirty) return errors
      !this.$v.variation.sku.required && errors.push('Please enter an SKU.')
      !(this.$v.variation.sku.minLength && this.$v.variation.sku.maxLength) &&
        errors.push('SKU length not in range. Minimum 3 maximum 25.')
      return errors
    },
    descriptionErrors() {
      const errors = []
      if (!this.$v.variation.description.$dirty) return errors
      !this.$v.variation.description.required &&
        errors.push('Please enter a description.')
      !(
        this.$v.variation.description.minLength &&
        this.$v.variation.description.maxLength
      ) &&
        errors.push('Description length not in range. Minimum 5 maximum 400.')
      return errors
    },
    priceErrors() {
      const errors = []
      if (!this.$v.variation.price.$dirty) return errors
      !this.$v.variation.price.required && errors.push('Please enter a price.')
      !(this.$v.variation.price.minValue && this.$v.variation.price.maxValue) &&
        errors.push('Price not in range. Minimum 0 maximum 99999.')
      return errors
    },
    graceErrors() {
      const errors = []
      if (!this.$v.variation.grace.$dirty) return errors
      !(this.$v.variation.grace.minValue && this.$v.variation.grace.maxValue) &&
        errors.push('Grace period not in range. Minimum 0, maximum 99.')
      return errors
    },
    intervalErrors() {
      const errors = []
      if (!this.$v.variation.interval.$dirty) return errors
      !(
        this.$v.variation.interval.minValue &&
        this.$v.variation.interval.maxValue
      ) && errors.push('Grace period not in range. Minimum 0, maximum 999.')
      return errors
    },
    ...appConfig
  },
  beforeMount() {
    this.product = this.initProduct
    this.variation = this.initVariation
    this.loadProductRecurrence()
    this.loadCurrencies()
  },
  methods: {
    changeRecurrence() {
      this.variation.interval = this.variation.recurrence == 'none' ? 0 : 1
    },
    reset() {},
    cancel() {
      this.reset()
      this.$emit('cancel')
    },
    loadProductRecurrence() {
      return axios
        .get(this.baseURL + '/product/recurrence', {})
        .then(response => {
          if (response.status == 200) {
            this.productRecurrence = response.data.data
            this.variation.recurrence = this.productRecurrence[0].value
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadCurrencies() {
      return axios
        .get(this.baseURL + '/product/currencies', {})
        .then(response => {
          if (response.status == 200) {
            this.currencies = response.data.data
            this.variation.currency = this.currencies[0].value
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    addProduct() {
      return axios
        .post(this.baseURL + '/product', {
          variation: this.variation,
          product: this.product,
          now: this.now(),
          businessid: this.businessid
        })
        .then(response => {
          if (response.status == 200) {
            this.toast('Product added succesfully.')
            this.variation = this.initVariation
            this.product = this.initProduct
            this.$emit('done')
          } else {
            this.toast(
              'Error adding product. ' + response.data.errors[0].message
            )
            return
          }
        })
    }
  }
}
</script>
